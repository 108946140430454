@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #273552;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.pac-container {
  border-radius: 8px;
  padding: 16px 0px;
}

.pac-logo::after {
  display: none;
}

.pac-item {
  height: 48px;
  padding: 16px 5px;
  font-size: 14px;
  line-height: 16px;
}

.pac-item:hover {
  background-color: #fff8e9;
}

.pac-icon-marker {
  margin-top: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gm-style-iw > button {
  display: none !important;
}

